import { Logger } from '~/helpers/logger';
import { Cart } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import applyCouponToCartGGL from '~/modules/checkout/queries/amastyApplyCouponToCart.gql';

export const removeCouponCommand = {
  execute: async (context: VsfContext, { currentCart, customQuery = { removeCouponFromCart: 'removeCouponFromCart' }, customHeaders = {} }) => {
    Logger.debug('[Magento]: Remove coupon from cart', { currentCart });

    // const { data, errors } = await context.$magento.api.removeCouponFromCart({
    //   cart_id: currentCart.id,
    // }, customQuery, customHeaders);

    const {data,errors} = await context.$magento.api.customQuery({
      query: applyCouponToCartGGL,
      queryVariables: {cart_id: currentCart.id,coupon_code: ''},
      customHeaders: customHeaders,             
    });

    Logger.debug('[Result]:', { data });

    return {
       // @ts-ignore
      updatedCart: data.amastyApplyCouponToCart?.cart as unknown as Cart,
      updatedCoupon: { code: '' },
      errors,
    };
  },
};
