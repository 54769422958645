
































  import { defineComponent } from '@nuxtjs/composition-api';
  import { SfModal, SfLink } from '@storefront-ui/vue';
  import { useUiState } from '~/composables/useUiState';
  export default defineComponent({
    name: 'HelpModal',
    components: {
      SfModal,
      SfLink
    },
    setup(_, { emit }) {
        const { toggleCartSidebar, toggleWishlistSidebar, toggleLoginModal, toggleHelpModal } = useUiState();
        const { isHelpModelOpen } = useUiState();
  
        return {
            isHelpModelOpen,
            toggleHelpModal
        };
    },
});
