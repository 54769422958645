
































import LazyHydrate from 'vue-lazy-hydration';
import { useRoute, defineComponent, onMounted, ref, useContext } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import AppHeader from '~/components/AppHeader.vue';
import BottomNavigation from '~/components/BottomNavigation.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import TopBar from '~/components/TopBar/TopBar.vue';
import TopMenu from '~/components/TopBar/TopMenu.vue';
import MainNavigation from '~/components/TopBar/MainNavigation.vue';
import MainNavigation2 from '~/components/TopBar/MainNavigation2.vue';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useCart } from '~/modules/checkout/composables/useCart';
import HelpModal from '~/modules/czar/HelpModal/HelpModal.vue';
import tickerBlock from '~/components/czar/customTickerBar/tickerBlock.vue';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    BottomNavigation,
    IconSprite,
    TopBar,
    TopMenu,
    MainNavigation,
    MainNavigation2,
    AppFooter: () => import(/* webpackPrefetch: true */ '~/components/Footer/AppFooter.vue'),
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
    WishlistSidebar: () => import(/* webpackPrefetch: true */ '~/modules/wishlist/components/WishlistSidebar.vue'),
    LoginModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/LoginModal.vue'),
    CheckoutLoginModal: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CheckoutLoginModal.vue'),
    HelpModal,
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
    tickerBlock
  },

  setup() {
    const route = useRoute();
    const customerStore = useCustomerStore();
    const { app } = useContext();
    const { setCart } = useCart();
    const {
      isCartSidebarOpen, isWishlistSidebarOpen, isLoginModalOpen, toggleLoginModal, isCheckoutLoginModalOpen, toggleCheckoutLoginModal, isHelpModelOpen, toggleHelpModal
    } = useUiState();

    onMounted(async () => {
      /* const token = route.value.query.token;
      if (token && token != null) {
        try {
          const apiState = app.context.$vsf.$magento.config.state;
          customerStore.setIsLoggedIn(true);
          apiState.setCustomerToken(token);
          const currentCartId = apiState.getCartId();
          const cart = await app.context.$vsf.$magento.api.customerCart();
          const newCartId = await cart.data.customerCart.id;
          try {
            if (newCartId && currentCartId && currentCartId !== newCartId) {
              const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts(
                {
                  sourceCartId: currentCartId,
                  destinationCartId: newCartId,
                },
              );
              setCart(dataMergeCart.mergeCarts);
              apiState.setCartId(dataMergeCart.mergeCarts.id);
            } else {
              setCart(cart.data.customerCart);
            }
          } catch {
            setCart(cart.data.customerCart);
          }
        } finally {

        }
      } else {

      } */
    });

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      isCheckoutLoginModalOpen,
      toggleCheckoutLoginModal,
      isHelpModelOpen,
      toggleHelpModal,
      route,
    };
  },
  head: {
    link: [{ rel: 'stylesheet', href: '/_nuxt/fonts.css' }],
  },
});
