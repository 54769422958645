




















import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton, SfTopBar } from '@storefront-ui/vue';
/* import { useTopBar } from './useTopBar'; */

export default defineComponent({
  components: {
    CurrencySelector: () => import('../CurrencySelector.vue'),
    StoreSwitcher: () => import('../StoreSwitcher.vue'),
    SfTopBar,
    SfButton,
  },
  setup() {
    /* const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar(); */

    return {
      /* hasCurrencyToSelect,
      hasStoresToSelect, */
    };
  },
});

