








import { defineComponent } from '@nuxtjs/composition-api';
import { useCmsBlocks } from "~/components/czar/customTickerBar/cmsBlocks";
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'TextTickerBlock',
  components: {

  },
  props: {
    
  },
  setup(props) {
    const cmsBlocks = useCmsBlocks();
    const { items } = storeToRefs(cmsBlocks);    
    var queryVariables = { identifiers: ["custom-ticker"] };
    cmsBlocks.fetchCmsBlock(queryVariables);
    return {
        items,
    };
  },
});

