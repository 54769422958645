import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import {
  Cart,
  CartItemInput,
} from '~/modules/GraphQL/types';
import { CustomQuery, CustomHeaders } from '~/types/core';
import addProductsToCartGGL from '~/modules/checkout/queries/addProductsToCart.ggl';
import { ref } from '@nuxtjs/composition-api';

/** Params object used to add items to a cart */
export declare type AddProductsToCartInput = {
  cartId: string;
  cartItems: CartItemInput[];
};

export const addItemCommand = {
  execute: async (
    context: VsfContext,
    {
      product,
      quantity,
      currentCart,
      productConfiguration,
      customQuery,
      customHeaders,
    },
  ) => {
    Logger.debug('[Magento]: Add item to cart', {
      product,
      quantity,
      currentCart,
    });

    const apiState = context.$magento.config.state;
    const cartId = apiState.getCartId();

    if (!product) {
      return;
    }
    
    const apiInput = ref({});

    // eslint-disable-next-line no-underscore-dangle
    switch (product.__typename) {
      case 'SimpleProduct':
        const simpleCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              warranty: product?.warranty
            },
          ],
        };
        apiInput.value = simpleCartInput;
        break;

      case 'ConfigurableProduct':
        const selectedOptions = Object.values(productConfiguration as object);

        const configurableCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              warranty: product?.warranty,
              selected_options: selectedOptions,
            },
          ],
        };
        apiInput.value = configurableCartInput;
        break;

      case 'BundleProduct':
        const createEnteredOptions = () =>
        // eslint-disable-next-line implicit-arrow-linebreak
          product.bundle_options.map((bundleOption) => ({
            ...bundleOption,
            value: bundleOption.value.toString(),
          }));

        const bundleCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              warranty: product?.warranty,
              entered_options: createEnteredOptions(),
            },
          ],
        };
        apiInput.value = bundleCartInput;
        break;
      case 'DownloadableProduct':
        const downloadableCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              warranty: product?.warranty
            },
          ],
        };
        apiInput.value = downloadableCartInput;
        break;
      case 'VirtualProduct':
        const virtualCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              warranty: product?.warranty,
            },
          ],
        };
        apiInput.value = virtualCartInput;
        break;
      case 'GroupedProduct':
        const groupedCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: product.items.map((item) => ({
            quantity,
            sku: item.product.sku,
            warranty: product?.warranty
          })),
        };
        apiInput.value = groupedCartInput;
        break;

      default:
        // eslint-disable-next-line no-underscore-dangle
        throw new Error(`Product Type ${product.__typename} not supported in add to cart yet`);
    }

        const {data,errors} = await context.$magento.api.customQuery({
          query: addProductsToCartGGL,
          queryVariables: apiInput.value,
          customHeaders: customHeaders,             
        });

        Logger.debug('[Result]:', { data: data });

        // @ts-ignore
        if (!data?.cart && errors?.length) {
          throw errors[0];
        }

       // @ts-ignore
        if (data.addProductsToCart.cart.id != cartId) {
          // @ts-ignore
          apiState.setCartId(data.addProductsToCart.cart.id);
        }

          //  @ts-ignore eslint-disable-next-line consistent-return
          return data.addProductsToCart.cart as unknown as Cart;    

  },
};
