




























































































































































































import {
  SfOverlay, SfHeader, SfButton, SfBadge, SfLink,
  SfBottomNavigation
} from '@storefront-ui/vue';
//import { useTopMenu } from './useTopMenu';
import { useTopMenu } from "~/components/TopBar/useTopMenu";
import { generateLink } from '~/helpers/addBasePath';
import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  useFetch,
} from '@nuxtjs/composition-api';
//import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
//import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import {
  useUiHelpers,
  useUiState,
} from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useUser } from '~/modules/customer/composables/useUser';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';
import HeaderLogo from '~/components/HeaderLogo.vue';
import SvgImage from '~/components/General/SvgImage.vue';
/* import { useTopBar } from './TopBar/useTopBar'; */

import { useCategoryStore } from '~/modules/catalog/category/stores/category';

export default defineComponent({
  components: {
    SfBottomNavigation,
    SfLink,
    // HeaderNavigation,
    SfHeader,
    SfOverlay,
    HeaderLogo,
    SvgImage,
    SfButton,
    SfBadge,
    CurrencySelector: () => import('~/components/CurrencySelector.vue'),
    StoreSwitcher: () => import('~/components/StoreSwitcher.vue'),
    SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
    SearchResults: () => import(
      /* webpackPrefetch: true */ '~/components/Header/SearchBar/SearchResults.vue'
    ),
  },
  setup() {
    let topMenues = {
      "data": {
        "getMegaMenuByGroupTitle": [
          {
            "title": "Commercial, Gov’t, & Edu",
            "link": "comm-gov-edu",
            "target": "",
            "custom_class": "",
            "__typename": "MenuInterface"
          },
          {
            "title": "Rentals",
            "link": "https://www.mpexrentals.com",
            "target": "target=\"_blank\"",
            "custom_class": "",
            "__typename": "MenuInterface"
          },
          {
            "title": "Take a Class",
            "link": "https://mpexlearningstudio.com",
            "target": "target=\"_blank\"",
            "custom_class": "",
            "__typename": "MenuInterface"
          },
          {
            "title": "Photo Lab",
            "link": "http://midwestphotopix.com",
            "target": "target=\"_blank\"",
            "custom_class": "",
            "__typename": "MenuInterface"
          },
          {
            "title": "Blog",
            "link": "http://mpex-experience.com",
            "target": "target=\"_blank\"",
            "custom_class": "",
            "__typename": "MenuInterface"
          }
        ]
      },
      "loading": false,
      "networkStatus": 7
    };
    //const { topMenues } = useTopMenu();
    const router = useRouter();
    const { app } = useContext();
    const { toggleCartSidebar, toggleWishlistSidebar, toggleLoginModal, toggleHelpModal, isOpenHelpPopup, openHelpPopup } = useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
    // const { categories: categoryList, load: categoriesListLoad } = useCategory();

    /* const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar(); */
    const isSearchOpen = ref(false);
    const isMobileSearchOpen = ref(false);
    const topMenuSub = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);

    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(() => wishlistStore.wishlist?.items_count ?? 0);

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const accountIcon = computed(() => (isAuthenticated.value ? 'profile' : 'profile'));  /* for after login profile icon fill (isAuthenticated.value ? 'profile_fill' : 'profile') */


    const activeIcon = ref(true);

    const {
      /* toggleCartSidebar,
      toggleWishlistSidebar,
      toggleLoginModal, */
      toggleMobileMenu,
      isMobileMenuOpen,
    } = useUiState();
    // const categoryTree = ref<CategoryTree[]>([]);

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        toggleLoginModal();
      }
    };

    // useFetch(async () => {
    //   await categoriesListLoad({ pageSize: 20 });

    //   categoryTree.value = categoryList.value?.[0]?.children
    //     .filter((category) => category.include_in_menu);
    // });

    onMounted(async () => {
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        // eslint-disable-next-line promise/catch-or-return
        await loadWishlistItemsCount();
      }
    });

    const loadCategoryMenu = async () => {
      /* const categories = useCategoryStore();
      if (categories.categories === null) {
        await categories.load();
      } */
      toggleMobileMenu();
    };

    const mobilesearchtoggle = () => {
      isMobileSearchOpen.value = !isMobileSearchOpen.value;
    };

    const topMenuToggle = () => {
      topMenuSub.value = !topMenuSub.value;
    };

    return {
      activeIcon,
      topMenuToggle,
      topMenuSub,
      mobilesearchtoggle,
      isMobileSearchOpen,
      loadCategoryMenu,
      topMenues,
      generateLink,
      accountIcon,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      // categoryTree,
      getCatLink,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      toggleHelpModal,
      openHelpPopup,
      isOpenHelpPopup,
      /* hasCurrencyToSelect,
      hasStoresToSelect, */
    };
  },
});
