 export default `
  query getMegaMenuByGroupTitle($title:String!) {	
		getMegaMenuByGroupTitle(title:$title){
		  items_id
		  parent_id
		  title
		  link
		  target
		  li_class
		  a_class
		  a_id
		  icon
		  description
		  cols_nb
		  is_align_right
		  show_title
		  content_type
		  depth
		  end_level
		  sub_menu_div_class
		}
	  

  }
`;