import { Plugin } from '@nuxt/types';

    const helloextend: Plugin = async () => {
        setTimeout(function () {
            if (process.client) {
            // @ts-ignore
            if (Extend) {
                // @ts-ignore
                Extend.config({
                storeId: 'cb9140a0-e99c-4755-ae3f-1a09b470b8ea',
                region: 'US',
                locale: 'en_US'
                })
            }
            }
    }, 1000);  
};
export default helloextend;