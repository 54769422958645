import { defineStore } from 'pinia';
import { ref } from '@nuxtjs/composition-api';
import getcmsBlocks from '~/components/czar/customTickerBar/cmsBlocks.gql';
import { useApi } from '~/composables/useApi';

type CmsBlocksResponse = {
    cmsBlocks: {
        items: Array<{
          identifier: string;
          title: string;
          content: string;
        }>;
    };
};

export const useCmsBlocks = defineStore('cmsBlocks', () => {
    const { query } = useApi();
    const items = ref<CmsBlocksResponse['cmsBlocks']['items'] | null>(null);
  
    const fetchCmsBlock = async (queryVariables: any) => {
        try {
            const response = await query<CmsBlocksResponse>(getcmsBlocks, queryVariables);
            if (response?.data?.cmsBlocks?.items[0] != null && response?.data?.cmsBlocks?.items?.length >= 0) {
                items.value = response.data.cmsBlocks.items;
            } else {
                console.warn('CMS block not found or disabled in admin.');
                items.value = [];
            }
        } catch (error) {
            console.error('Error fetching CMS blocks:', error);
            items.value = [];
        }
    };
    return {
        items,
        fetchCmsBlock
    }
});