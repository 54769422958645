import { ref, onMounted } from '@nuxtjs/composition-api';
//import priceNotifyGql from '~/modules/catalog/product/queries/priceNotify.gql';
import MegaMenu from '~/modules/czar/megamenu/query/megaMenu.gql';
import { useApi } from '~/composables/useApi';

type priceNotifyResponse = {
  getMegaMenuByGroupTitle: [],
  };

 export function useMegaMenu(){
  const { query } = useApi();    
  const useMegaMenuFun = (queryVariables) => {
    
    const getMegaMenuByGroupTitle = ref<any | null>(null);
  
    
      query<priceNotifyResponse>(MegaMenu,queryVariables)
        .then((response) => {
          getMegaMenuByGroupTitle.value = response.data.getMegaMenuByGroupTitle;
        })
        .catch(() => {
         
        });
    
    
    return {
      getMegaMenuByGroupTitle,
    };
  };
return {useMegaMenuFun}
 }
 export default useMegaMenu;
//export useNotify;
//export default usePriceNotify;