
















 import { defineComponent } from '@nuxtjs/composition-api';
 import { SfLink} from '@storefront-ui/vue';
/*  import { useTopMenu } from './useTopMenu';
 import { generateLink } from '~/helpers/addBasePath'; */
 
 export default defineComponent({
   components: {
     SfLink,
   },
   setup() {
     /* const {topMenues } = useTopMenu(); */
     return {
       /* topMenues,
       generateLink, */
     };
   },
 });
 