import { defineStore } from 'pinia';
import type { Cart } from '~/modules/GraphQL/types';
/* add for remove route shhiping ,route */
interface CustomerState {
  cart: Cart,
}

export const useCartStore = defineStore('cart', {
  state: (): CustomerState => ({
    cart: {
      id: '', is_virtual: false, total_quantity: 0, shipping_addresses: []
    },
  }),
});
/* remove route shhiping
,route:{
        is_route_insured:false,
        insurance_price:0,
        currency:'USD'
      },
*/